import React, { useState, memo, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear } from "date-fns";

const YearPicker = ({ handleYearChange, isRequired, isCurrentYear,setYear }) => {
  const [selectedYear, setSelectedYear] = useState(null);

  const handleChange = (date) => {
    const year = getYear(date);
    console.log("the checking", { date, year });
    setSelectedYear(year);
    if (handleYearChange) {
      handleYearChange(year);
    }
  };

  useEffect(() => {
    if (isCurrentYear) {
      const currentYear = new Date().getFullYear();
      setSelectedYear(currentYear);
      setYear(currentYear)
    }
  }, []);

  return (
    <div className="d-flex mb-2 align-items-center w-100">
      <DatePicker
        selected={selectedYear ? new Date(selectedYear, 0, 1) : null}
        onChange={handleChange}
        showYearPicker
        dateFormat="yyyy"
        placeholderText="Click to select a year"
        className="year-picker"
      />
      {isRequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
};

export default memo(YearPicker);
