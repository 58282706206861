export const CategoryPanelManagerSidebarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "MONTH WISE REVENUE",
        path: "monthWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatus",
        cName: "sub-nav",
      },
    ],
  },

  // {
  //   title: "MOVEMENTS OF MEETING",
  //   subNav: [
  //     {
  //       title: "MOVEMENTS OF MEETING",
  //       path: "movementsOfMeeting",
  //       cName: "sub-nav",
  //     }
  //     // {
  //     //   title: "MOM2",
  //     //   path: "targetStatus",
  //     //   cName: "sub-nav",
  //     // },
  //   ],
  // },

  {
    title: "YOUTUBE PERFORMANCE",
    link: "teacherPerformanceCat",
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS DASHBOARD",
    subNav: [
      {
        title: "EXAM SYLLABUS",
        subNav: [
          {
            title: "CREATE",
            path: "createExamSyllabus",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamSyllabus",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "ALL ABOUT EXAM",
        path: "allAboutExam",
        cName: "sub-nav",
      },

      {
        title: "EXAM DATES",
        path: "createExamDates",
        cName: "sub-nav",
      },
      {
        title: "PYQ PDF",
        subNav: [
          {
            title: "CREATE",
            path: "createpyqpdf",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepyqpdf",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "EXAM PAPER ANALYSIS",
        path: "createExamPaperAnalysis",
        cName: "sub-nav",
      },
      {
        title: "CUT-OFF",
        path: "cutOff",
        cName: "sub-nav",
      },
      {
        title: "PATTERN",
        subNav: [
          {
            title: "CREATE",
            path: "createpattern",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepattern",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "RESULT DASHBOARD",
    subNav: [
      {
        title: "EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteResults",
            cName: "sub-nav",
          },
          {
            title: "COPY EXAM RESULT",
            path: "copyResultsList",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "NEW EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addExamResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamResults",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "SCHOLARSHIP EXAM RESULTS",
        path: "ScholarshipExamResult",
        cName: "sub-nav",
      },
      {
        title: "ADD RESULT",
        path: "resultCompilation",
        cName: "sub-nav",
      },
      {
        title: "STUDENT COUNT",
        path: "studentResult",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER DASHBOARD",
    subNav: [
      {
        title: "TEACHER TASK PLAN",
        path: "teacherTaskPlan",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN COMPLETE",
        path: "teacherTaskPlanComplete",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN PENDING",
        path: "teacherTaskPlanPending",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN NOT COMPLETED",
        path: "teacherTaskPlanNotCompleted",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN",
        subNav: [
          {
            title: "ADD TEACHER TASK",
            path: "addTeacherTaskCategory",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editTeacherTaskCategory",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "AD BANER",
    subNav: [
      {
        title: "ADD BANER",
        path: "addadbaner",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteadbaner",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER TASK PLAN",
    subNav: [
      {
        title: "ADD TEACHER TASK",
        path: "addTeacherTaskCategory",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editTeacherTaskCategory",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "UPCOMING BATCHES",
    subNav: [
      {
        title: "ADD",
        path: "UpcomingBatchesAdd",
        cName: "sub-nav",
      },
      {
        title: "EDIT / SEARCH",
        path: "UpcomingBatchesEdit",
        cName: "sub-nav",
      },
      {
        title: "UPCOMING Batch Analysis",
        path: "UpcomingBatchAnalysis",
        cName: "sub-nav",
      },
    ],
    link: "UpcomingBatches",
  },
];

export const CategoryPanelSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "MONTH WISE REVENUE",
        path: "monthWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "YOUTUBE ANALYSIS",
    subNav: [
      {
        title: "LIVE VIDEO PERFORMANCE",
        path: "teacherPerformanceCat",
        cName: "sub-nav",
      },
      {
        title: "OFF TOPIC PERFORMANCE",
        path: "offTopicPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "YOUTUBE DASHBOARD",
  //   subNav: [
  //     {
  //       title: "ADD YOUTUBE DASHBOARD",
  //       path: "addYoutubeData",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT / DELETE",
  //       path: "editYoutubeData",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },

  {
    title: "RESULT DASHBOARD",
    subNav: [
      {
        title: "EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteResults",
            cName: "sub-nav",
          },
          {
            title: "COPY EXAM RESULT",
            path: "copyResultsList",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "NEW EXAM RESULTS",
        subNav: [
          {
            title: "ADD RESULTS",
            path: "addExamResults",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamResults",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "SCHOLARSHIP EXAM RESULTS",
        path: "ScholarshipExamResult",
        cName: "sub-nav",
      },
      {
        title: "ADD RESULT",
        path: "resultCompilation",
        cName: "sub-nav",
      },
      {
        title: "STUDENT COUNT",
        path: "studentResult",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER DASHBOARD",
    subNav: [
      {
        title: "TEACHER TASK PLAN",
        path: "teacherTaskPlan",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN COMPLETE",
        path: "teacherTaskPlanComplete",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN PENDING",
        path: "teacherTaskPlanPending",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN NOT COMPLETED",
        path: "teacherTaskPlanNotCompleted",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN",
        subNav: [
          {
            title: "ADD TEACHER TASK",
            path: "addTeacherTaskCategory",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editTeacherTaskCategory",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "AD BANER",
    subNav: [
      {
        title: "ADD BANER",
        path: "addadbaner",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteadbaner",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PRIME CLASS",
    subNav: [
      {
        title: "ADD VIDEO",
        path: "addFreeVideo",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editFreeVideo",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "TEACHER TASK PLAN",
  //   subNav: [
  //     {
  //       title: "ADD TEACHER TASK",
  //       path: "addTeacherTaskCategory",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT / DELETE",
  //       path: "editTeacherTaskCategory",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "INFLUENCER",
    subNav: [
      {
        title: "ADD INFLUENCER",
        path: "addInfluencer",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "getInfluencer",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COMPETITOR",
    subNav: [
      {
        title: "ADD",
        path: "addCompetitor",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editCompetitor",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COMPETITOR ANALYSIS",
    subNav: [
      {
        title: "LIVE VIDEO",
        subNav: [
          {
            title: "ADD",
            path: "addLiveCompetitorAnalysis",
            cName: "sub-nav",
          },
          {
            title: "EDIT/DELETE",
            path: "editLiveCompetitorAnalysis",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "OFF TOPIC VIDEO",
        subNav: [
          {
            title: "ADD",
            path: "addOffVideoCompetitorAnalysis",
            cName: "sub-nav",
          },
          {
            title: "EDIT/DELETE",
            path: "editOffVideoCompetitorAnalysis",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "SOCIAL MEDIA LINKS",
    subNav: [
      {
        title: "ADD LINKS ",
        path: "socialMediaLinks",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "EditDeleteSocialMediaLink",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "UPCOMING BATCHES",
    subNav: [
      {
        title: "ADD",
        path: "UpcomingBatchesAdd",
        cName: "sub-nav",
      },
      {
        title: "EDIT / SEARCH",
        path: "UpcomingBatchesEdit",
        cName: "sub-nav",
      },
      {
        title: "UPCOMING Batch Analysis",
        path: "UpcomingBatchAnalysis",
        cName: "sub-nav",
      },
    ],
    link: "UpcomingBatches",
  },
  // {
  //   title: "ADD RESULT",
  //   link: "resultCompilation",
  // },
  // {
  //   title: "STUDENT RESULT",
  //   link: "studentResult",
  // },
];
