import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router-dom";
import {
  SubRoleEmpSidebarData,
  SubRoleSidebarData,
} from "../../Module/subRoleSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";
const ActiveUser = lazy(() => import("../CXOPanel/activeUser"));

const SubPayroll = lazy(() => import("./subRollPayroll"));
const ManageEmployee = lazy(() => import("./subRoleManageEmployee"));

const AddCompany = lazy(() => import("../SuperAdminPanel/addCompany"));
const EditDeleteCompany = lazy(() =>
  import("../SuperAdminPanel/editDeleteCompany")
);

const AddDepartment = lazy(() => import("../SuperAdminPanel/addDepartment"));
const EditDeleteDepartment = lazy(() =>
  import("../SuperAdminPanel/editDeleteDepartment")
);

const AddDesignation = lazy(() => import("../SuperAdminPanel/addDesignation"));
const EditDeleteDesignation = lazy(() =>
  import("../SuperAdminPanel/editDeleteDesignation")
);
const AddSubDepartment = lazy(() =>
  import("../SuperAdminPanel/addSubDepartment")
);
const EditSubDepartment = lazy(() =>
  import("../SuperAdminPanel/editSubDepartment")
);
const PayRollCtc = lazy(() => import("../SuperAdminPanel/payRollCtc"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const SubRollDashboard = () => {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={loginType === 2 ? SubRoleEmpSidebarData : SubRoleSidebarData}
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<ManageEmployee />}>
            <Route path="manageEmployee" element={<ManageEmployee />} />
            <Route path="payroll" element={<SubPayroll />} />
            <Route path="addCompany" element={<AddCompany />} />
            <Route path="editDeleteCompany" element={<EditDeleteCompany />} />
            <Route path="addDepartment" element={<AddDepartment />} />
            <Route
              path="editDeleteDepartment"
              element={<EditDeleteDepartment />}
            />
            <Route path="addDesignation" element={<AddDesignation />} />
            <Route
              path="editDeleteDesignation"
              element={<EditDeleteDesignation />}
            />
            <Route path="addSubDepartment" element={<AddSubDepartment />} />
            <Route path="editSubDepartment" element={<EditSubDepartment />} />
            <Route path="payRollCtc" element={<PayRollCtc />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route path="activeUser" element={<ActiveUser />} />
          </CommonRoutes>
        }
      </div>
    </div>
  );
};

export default SubRollDashboard;
