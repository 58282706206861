export const PublicationCXOPanelSidebarData = [
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TOTAL REVENUE",
        path: "transactionDetails",
        cName: "sub-nav",
      },

      {
        title: "TRANSACTION EDUTECH STUDY MATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION PUBLICATION",
        path: "transactionpublication",
        cName: "sub-nav",
      },
      {
        title: "PAYMENT IN PUBLICATION",
        path: "paymentPublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EMPLOYEE",
    subNav: [
      {
        title: "PAYROLL",
        path: "cxoPayroll",
        cName: "sub-nav",
      },
      {
        title: "EMPLOYEE DETAILS",
        path: "employeeDetails",
        cName: "sub-nav",
      },
      {
        title: "SAlARY ANALYSIS",
        path: "payRollCtc",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      {
        title: "SOURCE WISE ANALYSIS",
        path: "sourceWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "STATE WISE ANALYSIS",
        path: "stateWiseAnalysis",
        cName: "sub-nav",
      },

      {
        title: "REVENUE PROJECTION",
        path: "RevenueProjection",
        cName: "sub-nav",
      },
      {
        title: "REVENUE ANALYSIS",
        path: "cxoDashboard",
        cName: "sub-nav",
      },
      {
        title: "ALL EXAM WISE REVENUE NUMBER",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BOARD MEETING",
    subNav: [
      {
        title: "PUBLICATION",
        path: "boardMeetingPublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REPORTS",
    link: "downloadReports",
  },
];

export const PubCXOEmpPanelSidebarData = [];
