import "../../App.css";
import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router-dom";
import {
  PublicationEmpSidebarData,
  PublicationSidebarData,
} from "../../Module/publicationSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";
import PubBookHistory from "./StockManagement/pubBookHistory";
import DamageStock from "./StockManagement/damageStock";

const StudyMaterialOrders = React.lazy(() => import("./studyMaterialOrders"));

const TransactionPublication = React.lazy(() =>
  import("../Finance/transactionPublication")
);

const CreateCouponCodePublication = React.lazy(() =>
  import("./createCouponCodePublication")
);
const CreateCouponCodePublicationMultipleExam = React.lazy(() =>
  import("./createCouponCodePublicationMultipleExam")
);
const EditDeleteCouponCode = React.lazy(() => import("./editDeleteCouponCode"));

const CreateCouponCodeExam = React.lazy(() => import("./createCouponCodeExam"));
const CreateCouponCodeNew = React.lazy(() => import("./createCouponCodeNew"));
const EditDeleteCouponCodePublication = React.lazy(() =>
  import("./editDeleteCouponCodePublication")
);

const CreatePubliCounsellorCouponNew = React.lazy(() =>
  import("./createPubliCounsellorCouponNew")
);
const EditDeletePubliCounsellorCouponNew = React.lazy(() =>
  import("./editDeletePubliCounsellorCouponNew")
);
const CopyCounsellorCoupon = React.lazy(() =>
  import("../AdminPanel/copyCounsellorCoupon")
);

const StockManagement = React.lazy(() => import("./stockManagement"));
const StockOut = React.lazy(() => import("./stockOut"));
const BookHistory = React.lazy(() => import("./bookHistory"));
const OrderReturned = React.lazy(() => import("./orderReturned"));

const EditBookHistory = React.lazy(() => import("./editBookHistory"));
const UpdateStock = React.lazy(() => import("./updateStock"));

const CreateBooks = React.lazy(() => import("./createBooks"));
const EditDeleteBooks = React.lazy(() => import("./editDeleteBooks"));

const CreateAssignBooks = React.lazy(() => import("./createAssignBooks"));
const EditDeleteAssignBooks = React.lazy(() =>
  import("./editDeleteAssignBooks")
);

const CreateClassroomBatch = React.lazy(() => import("./createClassroomBatch"));
const EditDeleteClassroomBatch = React.lazy(() =>
  import("./editDeleteClassroomBatch")
);
const AddDispatchCharges = React.lazy(() =>
  import("./DispatchCharges/addDispatchCharges")
);
const EditDispatchCharges = React.lazy(() =>
  import("./DispatchCharges/editDispatchCharges")
);

const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const AddSellingSource = lazy(() => import("./SellingSource/addSellingSource"));
const EditSellingSource = lazy(() =>
  import("./SellingSource/editSellingSource")
);
const AddTds = lazy(() => import("./Tds/addTds"));
const EditTds = lazy(() => import("./Tds/editTds"));
const StockIn = lazy(() => import("./StockManagement/stockIn"));
const StockOuts = lazy(() => import("./StockManagement/stockOut"));
const OrderReturn = lazy(() => import("./StockManagement/orderReturn"));
const damageStock = lazy(() => import("./StockManagement/damageStock"));
const DamageStockIn = lazy(() => import("./StockManagement/damageStockIn"));
const StockAnalysis = lazy(() => import("./StockManagement/stockAnalysis"));




const Vendor = lazy(() => import("./Vendor/addEditVendor"));
function PublicationDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2 ? PublicationEmpSidebarData : PublicationSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<StudyMaterialOrders />}
          >
            <Route path="/" element={<StudyMaterialOrders />} />
            <Route
              path="studyMaterialOrders"
              element={<StudyMaterialOrders />}
            />
            <Route
              path="transactionPublication"
              element={<TransactionPublication />}
            />
            <Route
              path="createCouponCodePublication"
              element={<CreateCouponCodePublication />}
            />
            <Route
              path="createCouponCodePublicationMultipleExam"
              element={<CreateCouponCodePublicationMultipleExam />}
            />
            <Route
              path="editDeleteCouponCode"
              element={<EditDeleteCouponCode />}
            />

            <Route
              path="createCouponCodeExam"
              element={<CreateCouponCodeExam />}
            />
            <Route
              path="createCouponCodeNew"
              element={<CreateCouponCodeNew />}
            />
            <Route
              path="editDeleteCouponCodePublication"
              element={<EditDeleteCouponCodePublication />}
            />

            <Route
              path="createPubliCounsellorCouponNew"
              element={<CreatePubliCounsellorCouponNew />}
            />
            <Route
              path="editDeletePubliCounsellorCouponNew"
              element={<EditDeletePubliCounsellorCouponNew />}
            />
            <Route
              path="copyCounsellorCoupon"
              element={<CopyCounsellorCoupon />}
            />

            {/* 
              <Route
                path="createPubliCounsellorCoupon"
                element={<CreatePubliCounsellorCoupon />}
              />
              <Route
                path="editDeletePubliCounsellorCoupon"
                element={<EditDeletePubliCounsellorCoupon />}
              /> */}

            <Route path="stockManagement" element={<StockManagement />} />
            <Route path="stockOut" element={<StockOut />} />
            <Route path="orderReturned" element={<OrderReturned />} />
            <Route path="bookHistory" element={<BookHistory />} />
            <Route path="editBookHistory" element={<EditBookHistory />} />
            <Route path="updateStock" element={<UpdateStock />} />
            <Route path="createBooks" element={<CreateBooks />} />
            <Route path="editDeleteBooks" element={<EditDeleteBooks />} />

            <Route path="createAssignBooks" element={<CreateAssignBooks />} />
            <Route
              path="EditDeleteAssignBooks"
              element={<EditDeleteAssignBooks />}
            />

            <Route
              path="createClassroomBatch"
              element={<CreateClassroomBatch />}
            />
            <Route
              path="editDeleteClassroomBatch"
              element={<EditDeleteClassroomBatch />}
            />
            <Route path="addDispatchCharges" element={<AddDispatchCharges />} />
            <Route
              path="editDispatchCharges"
              element={<EditDispatchCharges />}
            />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route path="vendor" element={<Vendor />} />
            <Route path="addSellingSource" element={<AddSellingSource />} />
            <Route path="editSellingSource" element={<EditSellingSource />} />
            <Route path="addTds" element={<AddTds />} />
            <Route path="editTds" element={<EditTds />} />
            <Route path="stockIn" element={<StockIn />} />
            <Route path="StockOuts" element={<StockOuts />} />
            <Route path="orderReturn" element={<OrderReturn />} />
            <Route path="damageStockIn" element={<DamageStockIn />} />
            <Route path="damageStock" element={<DamageStock />} />
            <Route path="pubBookHistory" element={<PubBookHistory/>} />
            <Route path="stockAnalysis" element={<StockAnalysis />} />


          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default PublicationDashboard;
