import "../../App.css";
import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";
import { PublicationCXOPanelSidebarData } from "../../Module/PublicationCXOPanelSidebarData";

const TransactionDetails = React.lazy(() => import("./transactionDetails"));
const TransactionEdutechStudyMaterial = React.lazy(() =>
  import("./transactionEdutechStudyMaterial")
);
const TransactionPublication = React.lazy(() =>
  import("./transactionPublication")
);
const PaymentPublication = React.lazy(() => import("./paymentPublication"));
const CxoPayRoll = React.lazy(() => import("./cxoPayRoll"));
const EmployeeDetails = React.lazy(() => import("./employeeDetails"));
const PayRollCtc = React.lazy(() => import("./payRollCtc"));
const Source_Analysis = React.lazy(() => import("./sourceWiseAnalysis"));
const State_Analysis = React.lazy(() => import("./stateWiseAnalysis"));
const RevenueProjection = React.lazy(() => import("./revenueProjection"));
const CxoDashboard = React.lazy(() => import("./cxoDashboard"));
const AllExamWiseRevenue = React.lazy(() => import("./allExamWiseRevenue"));
const BoardMeetingPublication = React.lazy(() =>
  import("./boardMeetingPublication")
);
const DownloadReports = React.lazy(() => import("./downloadReports"));

function PubCXOPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar data={PublicationCXOPanelSidebarData} />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<TransactionDetails />}
          >
            <Route path="transactionDetails" element={<TransactionDetails />} />
            <Route
              path="transactionEdutechStudyMaterial"
              element={<TransactionEdutechStudyMaterial />}
            />
            <Route
              path="transactionPublication"
              element={<TransactionPublication />}
            />
            <Route path="paymentPublication" element={<PaymentPublication />} />
            <Route path="cxoPayroll" element={<CxoPayRoll />} />
            <Route path="employeeDetails" element={<EmployeeDetails />} />
            <Route path="payRollCtc" element={<PayRollCtc />} />
            <Route path="sourceWiseAnalysis" element={<Source_Analysis />} />
            <Route path="stateWiseAnalysis" element={<State_Analysis />} />
            <Route path="RevenueProjection" element={<RevenueProjection />} />
            <Route path="cxoDashboard" element={<CxoDashboard />} />
            <Route path="allExamWiseRevenue" element={<AllExamWiseRevenue />} />
            <Route
              path="boardMeetingPublication"
              element={<BoardMeetingPublication />}
            />
            <Route path="downloadReports" element={<DownloadReports />} />

            {/* <Route path="registeredUser" element={<RegisteredUser />} />
            <Route path="cxobatchwiseCount" element={<CxoBatchwiseCount />} />
            <Route path="admissionDetails" element={<AdmissionDetails />} />
            <Route path="datewiseCount" element={<DatewiseCount />} />
            <Route path="revenueDetails" element={<RevenueDetails />} />
            <Route path="allSourceLead" element={<StudentCount />} />
            <Route path="websiteLeadCount" element={<WebsiteLeadCount />} />
            <Route
              path="referenceManagerCount"
              element={<ReferenceManagerCount />}
            />
            <Route
              path="referenceTeamLeadCount"
              element={<ReferenceTeamLeadCount />}
            />
            <Route
              path="referenceLeadsCount"
              element={<ReferenceLeadsCount />}
            />
            <Route path="transactionEdutech" element={<TransactionEdutech />} />
            <Route
              path="transactionEdutechOnlineCourse"
              element={<TransactionEdutechOnlineCourse />}
            />
       
            <Route
              path="transactionPublication"
              element={<TransactionPublication />}
            />
            <Route path="classroomStudents" element={<ClassroomStudents />} />
            <Route path="teacherFeedback" element={<TeacherFeedback />} />
            <Route path="expertSupport" element={<ExpertSupport />} />
            <Route
              path="teacherLectureFeedback"
              element={<TeacherLectureFeedback />}
            />
            <Route path="assuredRevenue" element={<AssuredRevenue />} />
            <Route
              path="secondInstallmentPaid"
              element={<SecondInstallmentPaid />}
            />
            <Route
              path="secondInstallmentDue"
              element={<SecondInstallmentDue />}
            />
            <Route path="teacherRank" element={<TeacherRanking />} />
            <Route path="studentCouponAdd" element={<StudentCouponAdd />} />
            <Route path="studentCouponEdit" element={<StudentCouponEdit />} />
            <Route path="leadOverview" element={<LeadOverview />} />
            <Route path="dailyMeeting" element={<DailyMeeting />} />
            <Route
              path="teamLeadAssignedDateAnalysis"
              element={<TeamLeadAssignedDateAnalysis />}
            />
            <Route
              path="counsellorLeadOverview"
              element={<CounsellorLeadOverview />}
            />
            <Route
              path="leadAssignedDateAnalysis"
              element={<LeadAssignedDateAnalysis />}
            />
            <Route
              path="leadAssignedStatusOverview"
              element={<LeadAssignedStatusOverview />}
            />
            <Route path="completeLeadDetail" element={<CompleteLeadDetail />} />
            <Route path="addWPConfig" element={<AddWPConfiguration />} />
            <Route path="editWPConfig" element={<EditWPConfiguration />} />
            <Route path="oldPackageExist" element={<OldPackageExist />} />
            <Route
              path="messageDeliveredStatus"
              element={<MessageDeliveredStatus />}
            /> */}

            {/* 
            <Route path="leadAnalysis" element={<LeadAnalysis />} />
            <Route path="bdePerformance" element={<BdePerformance />} />
            <Route
              path="categoryPerformance"
              element={<CategoryPerformance />}
            />
            <Route
              path="socialMediaPerformance"
              element={<SocialMediaPerformance />}
            />
            <Route path="salesPerformance" element={<SalesPerformance />} />
            <Route path="overviewChannel" element={<OverviewChannel />} />
            <Route path="teacherPerformance" element={<TeacherPerformance />} />
            <Route path="activeUser" element={<ActiveUser />} />
            <Route path="examWiseRevenue" element={<ExamWiseRevenue />} />
            <Route path="registerCount" element={<RegisterCount />} />
            <Route path="referenceGenerated" element={<ReferenceGenerated />} />
            <Route
              path="boardMeetingEdutech"
              element={<BoardMeetingEdutech />}
            />
          
            <Route path="boardMeetingLeads" element={<BoardMeetingLeads />} />
            <Route
              path="teacherTaskPlanComplete"
              element={<TeacherTaskPlanComplete />}
            />
            <Route
              path="teacherTaskPlanPending"
              element={<TeacherTaskPlanPending />}
            />
            <Route
              path="teacherTaskPlanNotCompleted"
              element={<TeacherTaskPlanNotCompleted />}
            />
            <Route
              path="youtubeTargetPerformance"
              element={<YoutubeTargetPerformance />}
            />
            <Route path="getInfluencer" element={<GetInfluencer />} />
            <Route path="resultCompilation" element={<ResultCompilation />} />
            <Route path="studentResult" element={<StudentResult />} />
            <Route path="technicalSupport" element={<TechnicalSupportNew />} />
            <Route path="UpcomingBatchAnalysis" element={<BatchaAnalisis />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route
              path="influencerPayment"
              element={<InfluencerPayment type={2} />}
            /> */}
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default PubCXOPanelDashboard;
