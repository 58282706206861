export const PublicationSidebarData = [
  {
    title: "STUDY MATERIAL ORDERS",
    link: "studymaterialorders",
  },
  {
    title: "TRANSACTION PUBLICATION",
    link: "transactionpublication",
  },
  {
    title: "PUBLICATION COUPON CODE",
    subNav: [
      {
        title: "CREATE",
        path: "createCouponCodePublication",
        cName: "sub-nav",
      },

      {
        title: "CREATE MULTIPLE EXAM",
        path: "createcouponcodepublicationmultipleexam",
        cName: "sub-nav",
      },

      {
        title: "EDIT / DELETE",
        path: "editdeletecouponcode",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PUBLICATION COUNSELLOR EXAM COUPON",
    subNav: [
      {
        title: "CREATE ALL COUNSELLOR EXAM COUPONS",
        path: "createcouponcodeexam",
        cName: "sub-nav",
      },
      {
        title: "CREATE SINGLE COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecouponcodePublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PUBLICATION ALL COURSES COUNSELLOR COUPON & EXTENSION",
    subNav: [
      {
        title: "CREATE",
        path: "createPubliCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletePubliCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "COPY COUNSELLOR COUPON",
        path: "copyCounsellorCoupon",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "STOCK MANAGEMENT",
    subNav: [
      {
        title: "STOCK IN",
        path: "stockmanagement",
        cName: "sub-nav",
      },
      {
        title: "STOCK OUT",
        path: "stockOut",
        cName: "sub-nav",
      },
      {
        title: "BOOK HISTORY",
        path: "bookhistory",
        cName: "sub-nav",
      },
      {
        title: "ORDER RETURN",
        path: "orderReturned",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BOOKS",
    subNav: [
      {
        title: "CREATE",
        path: "createbooks",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletebooks",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN BOOKS",
    subNav: [
      {
        title: "CREATE",
        path: "createassignbooks",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteassignbooks",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CLASSROOM BATCH",
    subNav: [
      {
        title: "CREATE",
        path: "createclassroombatch",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteclassroombatch",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DISPATCH CHARGES",
    subNav: [
      {
        title: "CREATE",
        path: "addDispatchCharges",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDispatchCharges",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SELLING SOURCE",
    subNav: [
      {
        title: "ADD",
        path: "addSellingSource",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editSellingSource",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TDS",
    subNav: [
      {
        title: "ADD",
        path: "addTds",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editTds",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BOOKS STOCK MANAGEMENT",
    subNav: [
      {
        title: "STOCK IN",
        path: "stockIn",
      },
      {
        title: "STOCK OUT",
        path: "stockOuts",
      },
      {
        title: "STOCK ANALYSIS",
        path: "stockAnalysis",
      },
      {
        title: "ORDER RETURN",
        path: "orderReturn",
      },
      {
        title: "DAMAGE IN",
        path: "damageStockIn",
      },
      {
        title: "DAMAGE ANALYSIS",
        path: "damageStock",
      },
      {
        title: "BOOK HISTORY",
        path: "pubBookHistory",
      },
    ],
  },
  {
    title: "VENDOR",
    link: "vendor",
  },
];
export const PublicationEmpSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "STUDY MATERIAL ORDERS",
    link: "studymaterialorders",
  },
  {
    title: "TRANSACTION PUBLICATION",
    link: "transactionpublication",
  },
  {
    title: "PUBLICATION COUPON CODE",
    subNav: [
      {
        title: "CREATE",
        path: "createCouponCodePublication",
        cName: "sub-nav",
      },

      {
        title: "CREATE MULTIPLE EXAM",
        path: "createcouponcodepublicationmultipleexam",
        cName: "sub-nav",
      },

      {
        title: "EDIT / DELETE",
        path: "editdeletecouponcode",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PUBLICATION COUNSELLOR EXAM COUPON",
    subNav: [
      {
        title: "CREATE ALL COUNSELLOR EXAM COUPONS",
        path: "createcouponcodeexam",
        cName: "sub-nav",
      },
      {
        title: "CREATE SINGLE COUNSELLOR EXAM COUPONS",
        path: "createCouponCodeNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletecouponcodePublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PUBLICATION ALL COURSES COUNSELLOR COUPON & EXTENSION",
    subNav: [
      {
        title: "CREATE",
        path: "createPubliCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeletePubliCounsellorCouponNew",
        cName: "sub-nav",
      },
      {
        title: "COPY COUNSELLOR COUPON",
        path: "copyCounsellorCoupon",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "STOCK MANAGEMENT",
    subNav: [
      {
        title: "STOCK IN",
        path: "stockmanagement",
        cName: "sub-nav",
      },
      {
        title: "STOCK OUT",
        path: "stockOut",
        cName: "sub-nav",
      },
      {
        title: "BOOK HISTORY",
        path: "bookhistory",
        cName: "sub-nav",
      },
      {
        title: "ORDER RETURN",
        path: "orderReturned",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "BOOKS",
    subNav: [
      {
        title: "CREATE",
        path: "createbooks",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeletebooks",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSIGN BOOKS",
    subNav: [
      {
        title: "CREATE",
        path: "createassignbooks",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteassignbooks",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CLASSROOM BATCH",
    subNav: [
      {
        title: "CREATE",
        path: "createclassroombatch",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editdeleteclassroombatch",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DISPATCH CHARGES",
    subNav: [
      {
        title: "CREATE",
        path: "addDispatchCharges",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDispatchCharges",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SELLING SOURCE",
    subNav: [
      {
        title: "ADD",
        path: "addSellingSource",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editSellingSource",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TDS",
    subNav: [
      {
        title: "ADD",
        path: "addTds",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editTds",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "STOCK MANAGEMENT",
    subNav: [
      {
        title: "STOCK IN",
        path: "stockIn",
      },
      {
        title: "STOCK OUT",
        path: "stockOuts",
      },
      {
        title: "STOCK ANALYSIS",
        path: "stockAnalysis",
      },
      {
        title: "ORDER RETURN",
        path: "orderReturn",
      },
      {
        title: "DAMAGE STOCK",
        path: "damageStock",
      },
    ],
  },
  {
    title: "VENDOR",
    link: "vendor",
  },
];
