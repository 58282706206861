import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./Screen/Auth/login";
import SuccessSnackbar from "./Component/successSnackbar";
import ErrorSnackbar from "./Component/errorSnackbar";
import Backdrop from "@mui/material/Backdrop";

import SuperAdminDashboard from "./Screen/SuperAdminPanel/superAdminDashboard";
import AdminPanelDashboard from "./Screen/AdminPanel/adminDashboard";
import DataEntryPanelDashboard from "./Screen/DataEntryPanel/dataEntryDashboard";
import CategoryPanelDashboard from "./Screen/CategoryPanel/categoryPanelDashboard";
import PublicationDashboard from "./Screen/Publication/publicationDashboard";
import FinanceDashboard from "./Screen/Finance/financeDashboard";
import TeacherPanelDashboard from "./Screen/TeacherPanel/teacherPanelDashboard";
import ConfigDataDashboard from "./Screen/ConfigurationData/configDataDashboard";
import CXOPanelDashboard from "./Screen/CXOPanel/CXOPanelDashboard";
import SalesDashboard from "./Screen/SalesUpsellingPanel/salesDashboard";
import TechSupportDashboard from "./Screen/TechnicalSupportPanel/techSupportDashboard";
import InfraPanelDashboard from "./Screen/InfraPanel/infraPanelDashboard";
import VpOperationsPanelDashboard from "./Screen/VpOperationsPanel/vpOperationsDashboard";
import CounsellorPanelDashboard from "./Screen/CounsellorPanel/counsellorDashboard";
import PdfViewer from "./Component/pdfViewer";
import SEODashboard from "./Screen/SEOPanel/seoDashoard";
import UpSellingDashboard from "./Screen/UpSellingPanel/upSellingDashboard";
import BdeDashboard from "./Screen/BdePanel/bdeDashboard";
import SocialMediaDashboard from "./Screen/SocialMedialPanel/socialMediaDashboard";
import PaidAdsDashboard from "./Screen/PaidAdsPanel/paidMediaDashboard";
import SMTDashboard from "./Screen/SMTeamPanel/smTeamDashboard";
import ChatPage from "./Screen/TeacherPanel/ChatPage";
import TeacherTaskDashboard from "./Screen/TeacherTask/teacherTaskDashboard";
import AcademicHeadDashboard from "./Screen/AcademicHeadPanel/academicHeadDashboard";
import HrOperationDashboard from "./Screen/HrOperationPanel/hrOperationDashboard";
import IndiaPostAPi from "./Screen/IndiaPostAPI/indiaPostAPI";
import YoutubeDashboard from "./Screen/YoutubePanel/youtubeDashboard";
import InfraManagerPanelDashboard from "./Screen/InfraManagerPanel/infraManagerPanelDashboard";
import StartQuestionPoll from "./Screen/TeacherPanel/startQuestionPoll";
import ManagerPanelDashboard from "./Screen/ManagerPanel/managerDashboard";
import ResultPanelDashboard from "./Screen/ResultPanel/resultDashboard";
import DigitalMarketingDashboard from "./Screen/DigitalMakertingPanel/digitalMarketingDashboard";
import CloDashboard from "./Screen/CloPanel/cloDashboard";
import SalesDirectorPanelDashboard from "./Screen/SalesDirectorPanel/SalesDirectorPanelDashboard";
import SubRollDashboard from "./Screen/SubRolePanel/subRoleDashboard";
import InfluencerDashboard from "./Screen/InfluencerPanel/influencerDashboard";
import PubCXOPanelDashboard from "./Screen/PublicationCXO/PubCXOPanelDashboard";
import EmployeeDashboard from "./Screen/EmployeePanel/employeeDashboard";

function App() {
  const { loader } = useSelector((state) => ({
    loader: state?.Auth?.loader,
  }));

  return (
    <div className="App">
      {loader ? (
        <Backdrop className="loaderBackdrop" sx={{ color: "#fff" }} open>
          <span className="loader"></span>
        </Backdrop>
      ) : (
        ""
      )}
      <SuccessSnackbar />
      <ErrorSnackbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="SuperAdminPanel/*" element={<SuperAdminDashboard />} />
        <Route path="AdminPanel/*" element={<AdminPanelDashboard />} />
        <Route path="DataEntryPanel/*" element={<DataEntryPanelDashboard />} />
        <Route path="CategoryPanel/*" element={<CategoryPanelDashboard />} />
        <Route path="Publication/*" element={<PublicationDashboard />} />
        <Route path="Finance/*" element={<FinanceDashboard />} />
        <Route path="TeacherPanel/*" element={<TeacherPanelDashboard />} />
        <Route path="ConfigurationData/*" element={<ConfigDataDashboard />} />
        <Route path="CXOPanel/*" element={<CXOPanelDashboard />} />
        <Route path="PubCXOPanel/*" element={<PubCXOPanelDashboard/>} />
        <Route path="SalesUpsellingPanel/*" element={<SalesDashboard />} />
        <Route
          path="TechnicalSupportPanel/*"
          element={<TechSupportDashboard />}
        />
        <Route path="InfraPanel/*" element={<InfraPanelDashboard />} />
        <Route
          path="VpOperationsPanel/*"
          element={<VpOperationsPanelDashboard />}
        />
        <Route path="SEOPanel/*" element={<SEODashboard />} />
        <Route path="AcademicHeadPanel/*" element={<AcademicHeadDashboard />} />
        <Route path="UpSellingPanel/*" element={<UpSellingDashboard />} />
        <Route
          path="counsellorPanel/*"
          element={<CounsellorPanelDashboard />}
        />
        <Route path="PdfViewer" element={<PdfViewer />} />
        <Route path="BdePanel/*" element={<BdeDashboard />} />
        <Route path="SocialMediaPanel/*" element={<SocialMediaDashboard />} />
        <Route path="PaidAdsPanel/*" element={<PaidAdsDashboard />} />
        <Route path="SMTPanel/*" element={<SMTDashboard />} />
        <Route path="TeacherTaskPanel/*" element={<TeacherTaskDashboard />} />
        <Route path="ChatPage" element={<ChatPage />} />
        <Route path="StartQuestionPoll" element={<StartQuestionPoll />} />
        <Route path="HrOperationPanel/*" element={<HrOperationDashboard />} />
        <Route path="indiapost" element={<IndiaPostAPi />} />
        <Route path="YoutubeDashboardPanel/*" element={<YoutubeDashboard />} />
        <Route
          path="InfraManagerPanel/*"
          element={<InfraManagerPanelDashboard />}
        />
        <Route path="managerPanel/*" element={<ManagerPanelDashboard />} />
        <Route path="resultPanel/*" element={<ResultPanelDashboard />} />
        <Route
          path="digitalMarketingPanel/*"
          element={<DigitalMarketingDashboard />}
        />
        <Route path="CloPanel/*" element={<CloDashboard />} />
        <Route
          path="SalesDirectorPanel/*"
          element={<SalesDirectorPanelDashboard />}
        />
        <Route path="SubRolePanel/*" element={<SubRollDashboard />} />
        <Route path="InfluencerPanel/*" element={<InfluencerDashboard />} />
        <Route path="EmployeePanel/*" element={<EmployeeDashboard/>} />
      </Routes>
    </div>
  );
}
export default App;
